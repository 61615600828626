import ROUTES from 'constants/routes';
import useUser from 'hooks/useUser';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const SideBar = ({ sideBarOpen, mobileScreen, setSideBarOpen }) => {
    const { hasAllAccess, isTrade, isWholeSale, isFinance, isSuperAdmin, user } = useUser();
    const [showCover, setShowCover] = useState(false);
    const timeout = useRef();

    const closeBar = () => {
        setShowCover(false);
        mobileScreen && setSideBarOpen(false);
    };

    useEffect(() => {
        if (sideBarOpen && mobileScreen) {
            timeout.current = setTimeout(() => {
                setShowCover(true);
            }, 30);
        } else {
            setShowCover(false);
            timeout.current && clearTimeout(timeout.current);
        }
    }, [sideBarOpen, mobileScreen]);

    return (
        <>
            {showCover && (
                <div
                    className={`fixed bottom-0 left-0 h-screen z-[1000] w-screen transition-colors duration-300 ${
                        showCover && 'bg-gray-600/40 backdrop-blur-[1px]'
                    }`}
                    onClick={() => closeBar()}
                />
            )}

            <aside
                className={`transition-all bg-white duration-300 p-5 pb-8 border-r overflow-y-auto ${
                    sideBarOpen
                        ? 'max-sm:fixed max-sm:h-[calc(100vh-58px)] !left-0 !bottom-0 max-475:w-[200px] max-sm:w-[230px] max-sm:!z-[1200] px-4'
                        : 'px-[9px]'
                }`}
            >
                <>
                    <p
                        className={`${
                            sideBarOpen
                                ? 'text-[11px] pb-4 font-semibold text-text pt-4'
                                : 'font-medium text-2xl text-center pb-3 text-text-light'
                        }`}
                    >
                        {sideBarOpen ? 'GENERAL' : '...'}
                    </p>
                    <Bar
                        content={'Dashboard'}
                        iconName={'house'}
                        path={ROUTES.HOMEPAGE}
                        open={sideBarOpen}
                        closeBar={closeBar}
                    />
                    {user?.role && (
                        <>
                            {(isTrade || hasAllAccess) && (
                                <Bar
                                    content={'Trades'}
                                    iconName={'add_business'}
                                    path={ROUTES.TRADES}
                                    open={sideBarOpen}
                                    closeBar={closeBar}
                                />
                            )}
                            {(isWholeSale || hasAllAccess) && (
                                <Bar
                                    content={'Sales'}
                                    iconName={'payments'}
                                    path={ROUTES.SALES}
                                    open={sideBarOpen}
                                    closeBar={closeBar}
                                />
                            )}
                            <Bar
                                content={'Expenses'}
                                iconName={'price_change'}
                                path={ROUTES.REQUISITIONS}
                                open={sideBarOpen}
                                closeBar={closeBar}
                            />

                            {(isWholeSale || hasAllAccess) && (
                                <Bar
                                    content={'Farmers'}
                                    iconName={'store'}
                                    path={ROUTES.FARMERS}
                                    open={sideBarOpen}
                                    closeBar={closeBar}
                                />
                            )}

                            {isSuperAdmin && (
                                <Bar
                                    content={'Storage'}
                                    iconName={'warehouse'}
                                    path={ROUTES.STORAGE}
                                    open={sideBarOpen}
                                    closeBar={closeBar}
                                />
                            )}

                            {hasAllAccess && (
                                <>
                                    <Bar
                                        content={'Inspections'}
                                        iconName={'content_paste_search'}
                                        path={ROUTES.INSPECTIONS}
                                        open={sideBarOpen}
                                        closeBar={closeBar}
                                    />
                                    <Bar
                                        content={'Online Sales'}
                                        iconName={'cloud_upload'}
                                        path={ROUTES.ONLINE}
                                        open={sideBarOpen}
                                        closeBar={closeBar}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {(isFinance || isSuperAdmin) && (
                        <>
                            <p
                                className={`mt-3 ${
                                    sideBarOpen
                                        ? 'text-[11px] pb-4 font-semibold text-text pt-4'
                                        : 'font-medium text-2xl text-center pb-3 text-text-light'
                                }`}
                            >
                                {sideBarOpen ? 'METRICS' : '...'}
                            </p>
                            <Bar
                                content={'Product CM2'}
                                iconName={'add_notes'}
                                path={ROUTES.CONTRIBUTION_MARGIN}
                                open={sideBarOpen}
                                closeBar={closeBar}
                            />
                            <Bar
                                content={'Location CM2'}
                                iconName={'file_map'}
                                path={ROUTES.LOCATIONS_CM2}
                                open={sideBarOpen}
                                closeBar={closeBar}
                            />
                            <Bar
                                content={'Inventory'}
                                iconName={'account_balance_wallet'}
                                path={ROUTES.INVENTORY}
                                open={sideBarOpen}
                                closeBar={closeBar}
                            />

                            <p
                                className={`mt-3 ${
                                    sideBarOpen
                                        ? 'text-[11px] pb-4 font-semibold text-text pt-4'
                                        : 'font-medium text-2xl text-center pb-3 text-text-light'
                                }`}
                            >
                                {sideBarOpen ? 'FINANCE' : '...'}
                            </p>
                            <Bar
                                content={'Requests'}
                                iconName={'request_quote'}
                                path={ROUTES.FINANCE_REQUISITIONS}
                                open={sideBarOpen}
                                closeBar={closeBar}
                            />
                        </>
                    )}
                </>
            </aside>
        </>
    );
};

const Bar = ({ path, content, iconName, open, closeBar }) => {
    const location = useLocation();
    const isOnPage = location.pathname === path;

    return (
        <Link
            className={`flex items-center rounded-md px-2.5 py-2.5 transition-colors duration-200 group mb-1 ${
                isOnPage ? 'bg-primary/80 pointer-events-none' : 'hover:bg-[#e6ffe8]'
            }`}
            to={path}
            onClick={closeBar}
        >
            <span
                className={`material-symbols-outlined ${
                    isOnPage ? 'text-white' : 'text-text/60 group-hover:text-primary'
                } ${open ? 'text-[23px]' : 'transition-all text-[28px] mx-auto'}`}
            >
                {iconName}
            </span>

            {open && (
                <span
                    className={`ml-3 mr-4 text-[14px] ${
                        isOnPage ? 'text-white' : 'text-text/80 group-hover:text-primary'
                    }`}
                >
                    {content}
                </span>
            )}
        </Link>
    );
};

export default SideBar;
