import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    trades: null,
    requisitions: null,
    sales: null,
    expenses: null,
    users: null,
    farmers: null,
    storage: null,
    logistics: null,
    inspections: null,
    onlineSales: null,
};

export const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setTrades: (state, { payload }) => {
            state.trades = payload;
        },
        setRequisitions: (state, { payload }) => {
            state.requisitions = payload;
        },
        setSales: (state, { payload }) => {
            state.sales = payload;
        },
        setExpenses: (state, { payload }) => {
            state.expenses = payload;
        },
        setUsers: (state, { payload }) => {
            state.users = payload;
        },
        setFarmers: (state, { payload }) => {
            state.farmers = payload;
        },
        setStorage: (state, { payload }) => {
            state.storage = payload;
        },
        setLogistics: (state, { payload }) => {
            state.logistics = payload;
        },
        setInspections: (state, { payload }) => {
            state.inspections = payload;
        },
        setOnlineSales: (state, { payload }) => {
            state.onlineSales = payload;
        },
    },
});

export const {
    setTrades,
    setRequisitions,
    setSales,
    setExpenses,
    setUsers,
    setFarmers,
    setStorage,
    setLogistics,
    setInspections,
    setOnlineSales,
} = app.actions;
export default app.reducer;
